import React, { useEffect, useState } from "react"
import styles from "./banner.module.css"
import Typo from "../typo"
import IconArrowLeft from "../../images/icon-arrow-left.svg"
import IconArrowRight from "../../images/icon-arrow-right.svg"
import { Link } from "gatsby"

const Banner = ({ banner, tag, title, links }) => {
  const defaultStyle = {
    backgroundImage: `url(${banner && banner.file ? banner.file.url : ``})`,
  }

  const [style, setStyle] = useState(defaultStyle)

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth < 1024) {
        setStyle({
          height: window.innerHeight - window.pageYOffset + "px",
          minHeight: window.innerWidth * 0.5625 + "px",
          ...defaultStyle,
        })
      } else {
        setStyle(defaultStyle)
      }
    }

    window.addEventListener("scroll", handleScroll)
    window.addEventListener("resize", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.removeEventListener("resize", handleScroll)
    }
  }, [])

  return (
    <div className={styles.root} style={style}>
      <Typo component={links[0] ? Link : "span"} to={links[0]} className={styles.prev}>
        <span>Prev</span>
        <IconArrowLeft />
      </Typo>

      <Typo component={links[1] ? Link : "span"} to={links[1]} className={styles.next}>
        <IconArrowRight />
        <span>Next</span>
      </Typo>

      <div className={styles.text}>
        <Typo type="text600" component="div" className={styles.tag}>{tag ? tag.title : ``}</Typo>
        <Typo type="title500" component="h2" className={styles.title}>{title}</Typo>
      </div>
    </div>
  )
}

export default Banner