import React, { useEffect, useRef, useState } from "react"
import styles from "./mobile.module.css"
import Swiper from "swiper"
import imgMobileFrame from "../../images/mobile-frame.png"
import Typo from "../typo"
import Cursor from "../cursor"
import IconDrag from "../../images/icon-drag.svg"

const Mobile = ({ images }) => {
  const sliderEl = useRef()

  useEffect(() => {
    const slider = new Swiper(sliderEl.current, {
      slidesPerView: "auto",
      spaceBetween: 25,
      slidesOffsetBefore: 25,
      slidesOffsetAfter: 25,
      breakpoints: {
        1280: {
          spaceBetween: 50,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          centeredSlides: true,
        },
      },
    })

    return () => {
      slider.destroy()
    }
  }, [])

  const [isTouch, setIsTouch] = useState(false)

  useEffect(() => {
    const handler = () => {
      setIsTouch(true)
      window.removeEventListener("touchstart", handler, true)
    }

    window.addEventListener("touchstart", handler, true)

    return () => {
      window.removeEventListener("touchstart", handler, true)
    }
  }, [])

  const cursor = useRef()
  const [cursorVisible, setCursorVisible] = useState(false)

  const handleMouseOver = () => {
    if (!isTouch) {
      setCursorVisible(true)
    }
  }

  const handleMouseOut = () => {
    if (!isTouch) {
      setCursorVisible(false)
    }
  }

  const handleMouseMove = (evt) => {
    if (!isTouch) {
      cursor.current.style.left = evt.clientX + "px"
      cursor.current.style.top = evt.clientY + "px"
    }
  }

  return (
    <div
      className={styles.root}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onMouseMove={handleMouseMove}
    >
      <Cursor
        ref={cursor}
        Icon={IconDrag}
        text="DRAG LEFT ‧ DRAG RIGHT ‧"
        letterSpacing="2.5px"
        iconSize="50px"
        visible={cursorVisible}
      />

      <div className="swiper-container" ref={sliderEl}>
        <div className="swiper-wrapper">
          {images
            ? images.map((image, i) => (
                <div className={styles.slide + " swiper-slide"} key={i}>
                  <figure className={styles.figure}>
                    <img
                      className={styles.frame}
                      src={imgMobileFrame}
                      alt="frame"
                    />
                    <img
                      className={styles.img}
                      src={image.file ? image.file.url : ``}
                      alt={image.title}
                    />
                    <Typo type="text" component="figcaption">
                      {image.title}
                    </Typo>
                  </figure>
                </div>
              ))
            : ``}
        </div>
      </div>
    </div>
  )
}

export default Mobile