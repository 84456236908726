import React, { forwardRef } from "react"
import styles from "./cursor.module.css"

const Cursor = forwardRef(({ Icon, text, visible, letterSpacing, iconSize }, ref) => {
  return (
    <div
      className={styles.root}
      style={{ display: visible ? "block" : "none" }}
      ref={ref}
    >
      <Icon className={styles.icon} width={iconSize} height={iconSize} />

      <svg viewBox="0 0 130 130" className={styles.text}>
        <path
          id="curve"
          fill="none"
          d="M65,15L65,15c27.614,0,50,22.386,50,50s-22.386,50-50,50S15,92.614,15,65c0-27.232,21.771-49.38,48.858-49.987"
        />
        <text letterSpacing={letterSpacing}>
          <textPath alignmentBaseline="top" xlinkHref="#curve">
            {text}
          </textPath>
        </text>
      </svg>
    </div>
  )
})

export default Cursor