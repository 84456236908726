import React from "react"
import styles from "./images.module.css"
import Typo from "../typo"

const Figure = ({ file, title }) => {
  return (
    <figure>
      <Typo type="text300" component="figcaption">{title}</Typo>
      <img src={file ? file.url : ``} alt={title} />
    </figure>
  )
}

const Images = ({ __typename, ...module }) => {
  const renderModule = () => {
    switch (__typename) {
      case "ContentfulProject1ColumnImage":
        return (
          <div className={styles.fullWidth}>
            {module.image ? <Figure {...module.image} /> : ``}
          </div>
        )

      case "ContentfulProject2ColumnImages":
        return (
          <div className={styles.twoColumns}>
            {module.imageLeft ? <Figure {...module.imageLeft} /> : ``}
            {module.imageRight ? <Figure {...module.imageRight} /> : ``}
          </div>
        )

      default:
        return ""
    }
  }

  return <div className={styles.root}>{renderModule()}</div>
}

export default Images