import React, { useMemo } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import styles from "./detail.module.css"
import Banner from "../../components/detail/banner"
import { Link, graphql } from "gatsby"
import IconGrid from "../../images/icon-grid.svg"
import Description from "../../components/detail/description"
import Images from "../../components/detail/images"
import Video from "../../components/detail/video"
import Mobile from "../../components/detail/mobile"
import Related from "../../components/detail/related"

const Detail = ({ pageContext, data }) => {
  const links = useMemo(() => {
    const { slug } = pageContext
    const projects = data.allContentfulProject.edges
    const index = projects.findIndex(({ node }) => node.slug === slug)

    if (index === -1) {
      return [null, null]
    }

    const prevIndex = index === 0 ? null : index-1
    const nextIndex = index === projects.length-1 ? null : index+1

    const prev = prevIndex === null ? null : `/work/${projects[prevIndex].node.slug}`
    const next = nextIndex === null ? null : `/work/${projects[nextIndex].node.slug}`
    return [prev, next]
  }, [pageContext, data])

  const renderModule = (module, i) => {
    switch (module.__typename) {
      case "ContentfulProject1ColumnImage":
      case "ContentfulProject2ColumnImages":
        return <Images key={i} {...module} />

      case "ContentfulProjectMobile":
        return <Mobile key={i} {...module} />

      case "ContentfulProjectVideo":
        return <Video key={i} {...module} client={data.contentfulProject.client} />

      default:
        return ""
    }
  }

  return (
    <Layout fixedHeader>
      <SEO
        title={data.contentfulProject.title}
        description={
          data.contentfulProject.description.json.content[0].content[0].value
        }
      />

      <div className={styles.list}>
        <Link to="/work">
          <IconGrid />
        </Link>
      </div>
      <Banner {...data.contentfulProject} links={links} />
      <Description {...data.contentfulProject} />
      {data.contentfulProject.modules
        ? data.contentfulProject.modules.map(renderModule)
        : ``}
      <Related related={data.contentfulProject.relatedProjects} />
    </Layout>
  )
}

export default Detail

export const query = graphql`
  query($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      title
      client
      link
      description {
        json
      }
      banner {
        file {
          url
        }
      }
      tag {
        title
      }
      deliverables {
        title
      }
      modules {
        ... on ContentfulProject1ColumnImage {
          image {
            file {
              url
            }
            title
          }
        }
        ... on ContentfulProject2ColumnImages {
          imageLeft {
            file {
              url
            }
            title
          }
          imageRight {
            file {
              url
            }
            title
          }
        }
        ... on ContentfulProjectMobile {
          images {
            file {
              url
            }
            title
          }
        }
        ... on ContentfulProjectVideo {
          youtube
          previewImage {
            title
            file {
              url
            }
          }
        }
      }
      relatedProjects {
        slug
        title
        client
        tag {
          title
        }
        banner {
          file {
            url
          }
        }
      }
    }

    allContentfulProject(sort: { fields: order, order: DESC }) {
      edges {
        node {
          slug
        }
      }
    }
  }
`