import React, { useRef, useState, useEffect } from "react"
import styles from "./video.module.css"
import Typo from "../typo"
import Marquee from "../marquee"
import IconPlay from "../../images/icon-play.svg"
import Cursor from "../cursor"
import VideoPopup from "../video-popup"

const Video = ({ client, previewImage, youtube }) => {
  const [isTouch, setIsTouch] = useState(false)

  useEffect(() => {
    const handler = () => {
      setIsTouch(true)
      window.removeEventListener("touchstart", handler, true)
    }

    window.addEventListener("touchstart", handler, true)

    return () => {
      window.removeEventListener("touchstart", handler, true)
    }
  }, [])

  const cursor = useRef()
  const [cursorVisible, setCursorVisible] = useState()

  const handleMouseOver = () => {
    if (!isTouch) {
      setCursorVisible(true)
    }
  }

  const handleMouseOut = () => {
    if (!isTouch) {
      setCursorVisible(false)
    }
  }

  const handleMouseMove = (evt) => {
    if (!isTouch) {
      cursor.current.style.left = evt.clientX + "px"
      cursor.current.style.top = evt.clientY + "px"
    }
  }

  const [popupVisible, setPopupVisible] = useState(false)

  const handleClick = () => {
    setPopupVisible(true)
  }

  return (
    <>
      <div className={styles.root}>
        <div className={styles.marquee}>
          <Marquee text={client} duration="11s" delay="-2s" />
          <Marquee text={client} duration="12s" />
          <Marquee text={client} duration="10s" delay="-1s" />
        </div>

        <div className={styles.image}>
          {previewImage ? (
            <figure>
              <button onClick={handleClick}>
                <img
                  src={previewImage.file ? previewImage.file.url : ``}
                  alt={previewImage.title}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  onMouseMove={handleMouseMove}
                />
              </button>
              <Typo type="text400" component="figcaption">
                {previewImage.title}
              </Typo>
            </figure>
          ) : (
            ``
          )}

          <Cursor
            Icon={IconPlay}
            text="PLAY VIDEO ‧ PLAY VIDEO ‧"
            letterSpacing="2.4px"
            iconSize="24px"
            ref={cursor}
            visible={cursorVisible}
          />
        </div>
      </div>
      <VideoPopup
        src={youtube}
        visible={popupVisible}
        onCloseClick={() => setPopupVisible(false)}
      />
    </>
  )
}

export default Video