import React from "react"
import styles from "./related.module.css"
import Marquee from "../marquee"
import { Link } from "gatsby"
import Typo from "../typo"

const Item = ({ slug, tag, title, client, banner }) => {
  return (
    <Link className={styles.item} to={`/work/${slug}`}>
      <img src={banner && banner.file ? banner.file.url : ``} alt={title} />
      <div className={styles.item__text}>
        <Typo type="text600" component="div" className={styles.item__tag}>{tag ? tag.title : ``}</Typo>
        <Typo type="title" component="h3" className={styles.item__title}>{title}</Typo>
        <Typo type="text300" component="div" className={styles.item__client}>{client}</Typo>
      </div>
    </Link>
  )
}

const Related = ({ related }) => {
  return (
    <div className={styles.root}>
      <div className={styles.marquee}>
        <Marquee text="you may also like" duration="10s" stroke />
      </div>

      {related ? (
        <div className={styles.wrapper}>
          {related[0] ? <Item {...related[0]} /> : ``}
          {related[1] ? <Item {...related[1]} /> : ``}
        </div>
      ) : (
        ``
      )}
    </div>
  )
}

export default Related