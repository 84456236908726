import React, { useEffect } from "react"
import styles from "./video-popup.module.css"

const VideoPopup = ({ src, onCloseClick, visible }) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.removeAttribute("style")
    }
  }, [visible])

  return (
    <>
      <div
        className={styles.backdrop + (visible ? ` ${styles.in}` : "")}
        onClick={onCloseClick}
      ></div>
      <div className={styles.container + (visible ? ` ${styles.in}` : "")}>
        <button className={styles.close} onClick={onCloseClick}>
          close
          <span></span>
          <span></span>
        </button>
        {visible && (
          <iframe
            src={src}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>
    </>
  )
}

export default VideoPopup