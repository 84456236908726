import React, { useEffect, useState, useRef } from "react"
import styles from "./marquee.module.css"
import Typo from "./typo"

const arr = [0,0,0,0,0,0,0,0]

const Marquee = ({ text, duration, delay, stroke }) => {
  const innerRef = useRef()
  const [calculatedDuration, setCalculatedDuration] = useState(duration)

  useEffect(() => {
    const el = innerRef.current
    const base = 4000
    const ratio = el.clientWidth / base
    const time = parseInt(duration)
    setCalculatedDuration(Math.round(ratio * time) + 's')
  }, [duration])

  const style = { animationDuration: calculatedDuration, animationDelay: delay }

  return (
    <div className={styles.root + (stroke ? ` ${styles.stroke}` : "")}>
      <div className={styles.inner} style={style} ref={innerRef}>
        {arr.map((v, i) => (
          <Typo type="text800" component="span" key={i}>
            {text}
          </Typo>
        ))}
      </div>
    </div>
  )
}

Marquee.defaultProps = {
  duration: "10s",
  delay: "0",
  stroke: false
}

export default Marquee