import React from "react"
import styles from "./description.module.css"
import Typo from "../typo"
import IconLink from "../../images/icon-link.svg"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Description = ({ client, deliverables, description, link }) => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <dl>
          <Typo type="text500" component="dt">
            Client
          </Typo>
          <Typo typo="text300" component="dd">
            {client}
          </Typo>
        </dl>
        <dl>
          <Typo type="text500" component="dt">
            Deliverables
          </Typo>
          <dd>
            <ul>
              {deliverables
                ? deliverables.map((item, i) => (
                    <Typo typo="text300" component="li" key={i}>
                      {item.title}
                    </Typo>
                  ))
                : ``}
            </ul>
          </dd>
        </dl>
        <Typo type="text300" component="div" className={styles.description}>
          {description ? documentToReactComponents(description.json, {}) : ``}
          <div className={styles.link}>
            {link ? (
              <a href={link} target="_blank" rel="external">
                {link}
                <IconLink />
              </a>
            ) : (
              ``
            )}
          </div>
        </Typo>
      </div>
    </div>
  )
}

export default Description